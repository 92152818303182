<template>
  <div>
    <el-page-header @back="$router.back()" :content="page.title"></el-page-header>
    <el-form class="form" size="small" label-width="200px" ref="form" :model="form" :rules="rules" :disabled="page.state==='detail'">
      <el-form-item label="第三方配置名称：" prop="name">
        <el-input class="input" v-model="form.name"></el-input>
        <!-- <p class="desc">已关联企业数量
          <span class="num">200<i class="el-icon-info"></i></span>
        </p> -->
      </el-form-item>
      <el-form-item label="配置码:">
        <el-input class="input" v-model="form.configCode"></el-input>
      </el-form-item>
      <el-form-item label="公钥:">
        <el-input class="textarea" type="textarea" :rows="5" v-model="form.pubKey"></el-input>
      </el-form-item>
      <el-form-item label="账户类型:" prop="organizationType">
        <el-select v-model="form.organizationType">

          <el-option label="企业账户" :value="1"></el-option>
          <el-option label="社区账户" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="二级域名:" prop="subDomains">
        <el-input class="input" v-model="form.subDomains"></el-input>
      </el-form-item>
      <el-form-item label="回退地址:" prop="fallbackAddress">
        <el-input class="input" v-model="form.fallbackAddress"></el-input>
      </el-form-item>
      <!-- <el-form-item label="是否允许新建账户:">
        <el-switch v-model="form.isNewUser" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4"></el-switch>
      </el-form-item> -->
      <el-form-item label="是否允许修改登录凭证:">
        <el-switch v-model="form.isModifyLoginCredentials" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4"></el-switch>
      </el-form-item>
      <el-form-item label="企业logo" prop="companyLogo">
        <span style="color:#999">图片比例：1*1</span>
        <jf-ossUpload-img :value="form.companyLogo" @result="(val)=>{form.companyLogo=val}" />
      </el-form-item>
      <el-form-item label="平台logo" prop="companyLogo">
        <span style="color:#999">图片比例：150*45</span>
        <jf-ossUpload-img :value="form.platformLogo" @result="(val)=>{form.platformLogo=val}" />
      </el-form-item>
      <el-form-item label="是否统一管理人数包:">
        <el-switch v-model="form.isPurchasersNumber" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4"></el-switch>
      </el-form-item>
      <el-form-item label="人数包:" v-if="form.isPurchasersNumber===1">
        <el-input-number v-model="form.purchasersNumber" :step="1" step-strictly :min="1" :max="999999"></el-input-number>
      </el-form-item>
      <el-form-item label="是否统一存储空间配额:">
        <el-switch v-model="form.isStorageSpaceQuota" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4"></el-switch>
      </el-form-item>
      <el-form-item label="空间配额:" v-if="form.isStorageSpaceQuota===1">
        <el-input-number v-model="form.storageSpaceQuota" :step="1" step-strictly :min="1" :max="999999"></el-input-number>
      </el-form-item>
      <el-form-item label="功能模块:" prop="moduleIds">
        <el-button size="mini" @click="chooseModulesShow = true">
          选择权限
        </el-button>
        管理平台{{ form.moduleIds ? form.moduleIds.length : 0 }}个项；
        App{{ form.appModuleIds ? form.appModuleIds.length : 0 }}个项
      </el-form-item>
      <el-form-item label="事件模板库:">
        <span style="color:#409eff"> {{ template.tempName }}</span>
        <el-button size="mini" @click="chooseEventShow = true">{{ form.templateId ? "重新选择" : "选择事件模板" }}</el-button>
      </el-form-item>
      <el-form-item label="默认基础分">
        <el-input type="number" class="input" v-model="form.defaultBaseScore"></el-input>
      </el-form-item>
      <el-form-item label="默认角色">
        <el-button size="mini" @click="addRole">添加角色</el-button>
        <ul class="role-list">
          <li v-for="(item,i) in form.defaultRole" :key="i">
            <span>角色名称：</span>
            <el-input class="input" size="mini" v-model="item.name"></el-input>
            <span class="blue-link" @click="chooseRoleAuth(item,i)">选择权限</span>
            <span class="ml20">管理平台{{item.menuIdList.length}}个项；App{{item.appMenuIdList.length}}个项</span>
            <i class="el-icon-delete" @click="removeRole(i)"></i>
          </li>
        </ul>
        <ChooseModules :show.sync="defaultRoleModuleShow" :moduleIds.sync="defaultRoleMenuIdList" @confirm="setRoleModules" :appModuleIds.sync="defaultRoleAppMenuIdList" />
      </el-form-item>
      <el-form-item label="默认奖扣权限">
        <el-button size="mini" @click="addRoleAuth">添加奖扣权限</el-button>
        <ul class="role-list">
          <li v-for="(item,i) in form.defaultAwardPermission" :key="i">
            <span>奖扣权限名称：</span>
            <el-input class="w120" size="mini" v-model="item.permissionName"></el-input>
            <span class="ml20">B分：</span>
            <el-input class="w120" size="mini" type="number" v-model="item.bScore"></el-input>
            <span class="ml20">A分：</span>
            <el-input class="w120" size="mini" type="number" v-model="item.aScore"></el-input>
            <span class="ml20">产值：</span>
            <el-input class="w120" size="mini" type="number" v-model="item.outputValue"></el-input>
            <i class="el-icon-delete" @click="removeAuth(i)"></i>
          </li>
        </ul>
      </el-form-item>
      <el-form-item label="积分类型" prop="pointsType">
        <el-radio v-model="form.pointsType" :label="0">B分</el-radio>
        <el-radio v-model="form.pointsType" :label="1">B分+A分</el-radio>
        <el-radio v-model="form.pointsType" :label="2">B分+A分+产值</el-radio>
      </el-form-item>
      <el-form-item label="开启超标事件:">
        <el-switch v-model="form.isExceedNormIncident" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <el-form-item label="开启临时事件:">
        <el-switch v-model="form.isTempIncident" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <el-form-item label="开启创建打印队列:">
        <el-switch v-model="form.isPrintQueue" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <el-form-item label="开启临时事件奖票:">
        <el-switch v-model="form.isTempTicket" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <el-form-item label="不显示默认轮播图:">
        <el-switch v-model="form.isNotDefaultSlideshow" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio v-model="form.status" :label="0">冻结</el-radio>
        <el-radio v-model="form.status" :label="1">正常</el-radio>
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.back()">取 消</el-button>
        <el-button @click="submit" type="primary">确 定</el-button>
      </el-form-item>
    </el-form>
    <ChooseEvent :show.sync="chooseEventShow" @confirm="setTemplateId" />
    <ChooseModules :show.sync="chooseModulesShow" :moduleIds.sync="form.moduleIds" :appModuleIds.sync="form.appModuleIds" />

  </div>
</template>
<script>
import ChooseEvent from "../list/chooseEvent";
import ChooseModules from "../list/chooseModules.vue"

export default {
  components: {
    ChooseEvent, ChooseModules,
  },
  data() {
    return {
      chooseModulesShow: false,
      chooseAppModulesShow: false,
      chooseEventShow: false,
      defaultRoleModuleShow: false,
      form: {
        moduleIds: [],
        appModuleIds: [],
        companyLogo: "", //企业默认logo
        fallbackAddress: "", // fallbackAddress
        isModifyLoginCredentials: 0, //是否允许修改登入凭证
        isNewUser: 1,//是否允许新建用户
        isNotDefaultSlideshow: 0,//是否不显示默认轮播图 0否 1是
        isPrintQueue: 0,//是否允许创建打印队列
        isTempIncident: 0, //是否允许临时事件
        name: '',
        pointsType: 0,//积分类型
        purchasersNumber: 10,//购买人数包
        storageSpaceQuota: 10, //存储空间配额(GB)
        organizationType: 0,//	组织类型 0社区；1企业
        status: 1,//状态 0：停用 1：正常
        isPurchasersNumber: 0,//是否统一管理人数包
        configCode: '',
        pubKey: '',
        defaultRole: [
          {
            name: '',
            menuIdList: [],
            appMenuIdList: []
          }
        ],
        defaultAwardPermission: [
          {
            permissionName: '',
            aScore: '',
            bScore: '',
            outputValue: ''
          }
        ]
      },
      defaultRoleMenuIdList: [],
      defaultRoleAppMenuIdList: [],
      defaultRoleIndex: 0,
      loading: false,
      // 选择的事件模板
      template: {},
      rules: {
        name: [
          { required: true, message: "第三方配置名称必填" },
        ],
        subDomains: [
          { required: true, message: "二级域名必填" },
        ],
        moduleIds: [
          {
            required: true,
            validator: this.moduleIdsRule,
          },
        ],
      },
      page: {
        title: '新增第三方配置',
        state: 'add'
      }
    }
  },
  created() {
    if (this.$route.query.state === 'edit') {
      this.title = '修改第三方配置';
      this.page.state = "edit"
    };
    if (this.$route.query.state === "detail") {
      this.page.title = '第三方配置详情';
      this.page.state = "detail"
    };
    this.getDetail()
  },
  methods: {
    setRoleModules(val) {
      console.log(this.form.defaultRole[this.defaultRoleIndex], val)
      this.$set(this.form.defaultRole[this.defaultRoleIndex], 'menuIdList', val.moduleIds);
      this.$set(this.form.defaultRole[this.defaultRoleIndex], 'appMenuIdList', val.appModuleIds);

    },
    chooseRoleAuth(item, i) {
      this.defaultRoleModuleShow = true;
      this.defaultRoleMenuIdList = item.menuIdList;
      this.defaultRoleAppMenuIdList = item.appMenuIdList;
      this.defaultRoleIndex = i
    },
    removeAuth(i) {
      this.form.defaultAwardPermission.splice(i, 1)
    },
    removeRole(i) {
      this.form.defaultRole.splice(i, 1)
    },
    addRoleAuth() {
      this.form.defaultAwardPermission.push({
        permissionName: '',
        aScore: '',
        bScore: '',
        outputValue: ''
      })
    },
    addRole() {
      this.form.defaultRole.push({
        name: '',
        menuIdList: [],
        appMenuIdList: []
      })
    },
    getDetail() {
      if (this.$route.query.id) {
        this.$get(`/platform-admin/thirdPartyConfig/${this.$route.query.id}`).then(res => {
          if (res && res.code === 0) {
            if(!res.data.defaultRole){
              res.data.defaultRole = []
            }
            if(!res.data.defaultAwardPermission){
              res.data.defaultAwardPermission = []
            }
            this.form = res.data;
            // 模板名称回显
            this.template = {
              tempName: this.form.tempName || '',
            };
            
          }
        })
      }
    },
    // 选择模板子组件传入的数据
    setTemplateId(item) {
      this.template = item || {};
      this.form.templateId = item.id || "";
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;

          this[this.page.state === 'edit' ? '$put' : '$post']('/platform-admin/thirdPartyConfig', this.form).then(res => {
            if (res && res.code === 0) {
              this.$message.success('保存成功');
              this.$router.back();
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    // 验证选择模板
    moduleIdsRule(rule, value, callback) {
      console.log(!this.form.moduleIds || !this.form.moduleIds.length)
      if (!this.form.moduleIds || !this.form.moduleIds.length) {

        callback(new Error("管理平台模块必选"));
      } else if (!this.form.appModuleIds || !this.form.appModuleIds.length) {
        callback(new Error("App模块必选"));
      } else {
        callback();
      }
    },
  }
}
</script>

<style lang='scss' scoped >
.ml20 {
  margin-left: 20px;
}
.textarea {
  width: 500px;
}
.form {
  margin-top: 15px;
}
.input {
  width: 200px;
}
.w120 {
  width: 120px;
}
.desc {
  color: #999;
  .num {
    color: #409eff;
    cursor: pointer;
    i {
      margin-left: 5px;
    }
  }
}
.role-list {
  background: #f6f6f6;
  font-size: 12px;
  padding: 15px;
  width: 800px;
  .blue-link {
    margin-left: 20px;
    color: #409eff;
    cursor: pointer;
    &:active {
      opacity: 0.7;
    }
  }
  .el-icon-delete {
    color: #ff6060;
    font-size: 18px;
    margin-left: 20px;
    cursor: pointer;
    &:active {
      opacity: 0.7;
    }
  }
}
/deep/ input[type="number"] {
  -moz-appearance: textfield;
}
/deep/ input[type="number"]::-webkit-inner-spin-button,
/deep/ input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
